.mCSB_scrollTools {
    width: 5px;
    
    .mCSB_dragger_bar {
        border-radius: 0 !important;
    }
    
    &.mCSB_scrollTools_horizontal,
    &.mCSB_scrollTools_vertical {
        margin: 0 !important;  
    }
    
    &.mCSB_scrollTools_horizontal {
        height: 10px;
    }
}

html {
    &:not(.ie9) {
        .mCS-minimal-dark {
            &.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                background: rgba(0,0,0,0.4);
            }

            &.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
                background: rgba(0,0,0,0.4);
            }
        }
    }

    &.ie9 {
        .mCS-minimal-dark {
            &.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                background: #000000;
            }

            &.mCSB_scrollTools_onDrag .mCSB_dragger .mCSB_dragger_bar {
                background: #000000;
            }
        }
    }
}

.mCSB_inside > .mCSB_container {
    margin-right: 0;
}