/*--------------------------------------------------
    Block Header
    Used for Heading outside the Cards.
---------------------------------------------------*/
.block-header {
    @media screen and (min-width: @screen-sm-min) {
        padding: 0 35px;
    }

    @media screen and (max-width: @screen-sm-max) {
        padding: 0 20px;
    }

    margin-bottom: 25px;
    position: relative;

    & > h2 {
        font-size: 15px;
        color: #777;
        margin: 0;
        font-weight: 400;
        text-transform: uppercase;

        & > small {
            display: block;
            text-transform: none;
            margin-top: 8px;
            margin-bottom: 20px;
            color: #9E9E9E;
            line-height: 140%;
        }
    }

    .actions {
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 4;
    }
}

/*-------------------------
    Actions
--------------------------*/
.actions {
    list-style: none;
    padding: 0;
    z-index: 3;
    margin: 0;

    & > li {
        display: inline-block;
        vertical-align: baseline;
    }

    & > li > a,
    & > a {
        width: 30px;
        height: 30px;
        display: inline-block;
        text-align: center;
        padding-top: 6px;

        & > i {
            color: #adadad;
            font-size: 20px;
        }

        &:hover {
            & > i {
                color: #000;
            }
        }
    }

    & > li.open,
    &.open {
        & > a {
            & > i {
                color: #000;
            }

            &:before {
                .scale(1);
                .opacity(1);
            }
        }
    }

    &.actions-alt {
        & > li {
            & > a > i {
                color: #fff;

                &:hover {
                    color: #fff;
                }
            }

            &.open {
                & > a {
                    & > i {
                        color: #fff;
                    }
                }
            }
        }
    }

    &.open {
        z-index: 4;
    }
}

/*-------------------------
    Collapse Menu Icons
--------------------------*/
.line-wrap {
    width: 18px;
    height: 12px;
    .transition(all);
    .transition-duration(300ms);
    margin: 12px 20px;

    .line{
        width: 18px;
        height: 2px;
        .transition(all);
        .transition-duration(300ms);
        background-color: #fff;

        &.center {
            margin: 3px 0;
        }
    }
}

.toggled {
    .line-wrap {
        .rotate(180deg);

        .line {
            &.top {
                width: 12px;
                transform: translateX(8px) translateY(1px) rotate(45deg);
                -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
            }

            &.bottom {
                width: 12px;
                transform: translateX(8px) translateY(-1px) rotate(-45deg);
                -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
            }
        }
    }
}

/*-------------------------
    Load More
--------------------------*/
.load-more {
    text-align: center;
    margin-top: 30px;

    a {
        padding: 8px 10px 6px;
        display: inline-block;
        background-color: @m-red;
        color: #FFF;
        border-radius: 2px;
        white-space: nowrap;

        i {
            font-size: 20px;
            vertical-align: middle;
            position: relative;
            margin-top: -2px;
        }

        &:hover {
            background-color: darken(@m-red, 10%);
        }
    }
}

/*-------------------------
    View More
--------------------------*/
.view-more {
    display: block;
    text-align: center;
    padding: 10px 10px 15px;
    line-height: 100%;
    font-size: 11px;
    margin-top: 10px;
    text-transform: uppercase;
    color: @text-muted;

    &:hover {
        color: @text-color;
    }
}


/*-------------------------
    Page Loader
--------------------------*/
html {
    &:not(.ismobile) {
        .page-loader {
            background: #fff;
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1000;

            .preloader {
                width: 50px;
                position: absolute;
                left: 50%;
                margin-left: -25px;
                top: 50%;
                margin-top: -55px;
                .animated(fadeIn, 3000ms);

                p {
                    white-space: nowrap;
                    position: relative;
                    left: -9px;
                    top: 22px;
                    color: #CCC;
                }
            }
        }
    }
    
    &.ismobile .page-loader {
        display: none;
    }
}

/*----------------------------------------
    Action Header
-----------------------------------------*/
.action-header {
    padding: 25px 30px;
    line-height: 100%;
    position: relative;
    z-index: 1;
    min-height: 65px;
    border-bottom: 1px solid #F7F7F7;

    .actions {
        position: absolute;
        top: 18px;
        right: 17px;
        z-index: 10;
    }
}

.ah-label {
    font-size: 15px;
}

.ah-search {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
    background-color: #454E53;
    display: none;
}

.ahs-input {
    border: 0;
    padding: 0 26px;
    height: 100%;
    font-size: 15px;
    width: 100%;
    line-height: 60px;
    background-color: #F7F7F7;
}

.ahs-close {
    font-style: normal;
    position: absolute;
    top: 23px;
    right: 25px;
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    cursor: pointer;
    background: #E2E2E2;
    width: 18px;
    height: 18px;
    color: #6B6B6B;
    border-radius: 50%;

    &:hover {
        opacity: 0.8;
    }
}



/*----------------------------------
    Backdrop
-----------------------------------*/
.ma-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
}


/*----------------------------------
    Avatar
-----------------------------------*/
[class*="avatar-img"] {
    border-radius: 50%;
}

.avatar-img {
    width: 42px;
}


/*----------------------------------
    Stats Arrows
-----------------------------------*/
.stats-arrow {
    width: 21px;
    height: 21px;
    border: 1px solid #eee;
    border-radius: 50%;
    text-align: center;
    vertical-align: text-top;
    margin-top: -3px;
    position: relative;
    top: 2px;
    margin-left: 5px;
    line-height: 20px;
}

.stats-arrow-up {
    color: @m-green;
    border-color: lighten(@m-green, 10%);
}

.stats-arrow-down {
    color: @m-red;
    border-color: lighten(@m-red, 10%);
}