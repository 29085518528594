.fileinput {
  position: relative;
  padding-right: 35px;

  .close {
    position: absolute;
    top: 5px;
    font-size: 12px;
    float: none;
    opacity: 1;
    font-weight: 500;
    border: 1px solid #ccc;
    width: 19px;
    text-align: center;
    height: 19px;
    line-height: 16px;
    border-radius: 50%;
    right: 0;
    visibility: hidden;

    &:hover {
      background: #eee;
    }
  }

  .btn-file {

  }

  .input-group-addon {
    padding: 0 10px;
    vertical-align: middle;
  }

  .fileinput-preview.download {
    line-height: 70px;
  }

  .fileinput-preview.thumbnail {
    width: 150px;
    height: 200px;
    position: relative;

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      width: auto;
      max-height: 100%;
      height: auto;
    }

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.fileinput-exists .close {
  visibility: visible;
}