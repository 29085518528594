/*------------------------------
    Next/Prev Months
-------------------------------*/
.actions-calendar {
    .calendar-next,
    .calendar-prev {
        & > i {
            font-size: 25px;
            position: relative;
            top: 2px;
        }
    }
}

/*------------------------------
    Tag color
-------------------------------*/
.event-tag {
    & > span {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 0 3px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;

        &.color-tag__default {
            border: 1px solid #eee;
            background-color: #fff !important;

            & > i:before {
                color: #333;
            }
        }

        &,
        & > i {
            .transition(all);
            .transition-duration(200ms);
        }

        & > input[type=radio] {
            margin: 0;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 2;
            cursor: pointer;
            .opacity(0);

            &:checked + i {
                .opacity(1);
                .scale(1);
            }
        }

        &:hover {
            .opacity(0.8);
        }

        & > i {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 4px 0 0 7px;
            .opacity(0);
            .scale(0);

            &:before {
                content: '\f26b';
                font-family: @font-icon-md;
                color: #fff;
                font-size: 16px;
                z-index: 1;
            }
        }
    }
}

/*-------------------------------------
    Main Calendar Seasonal headers
--------------------------------------*/
[data-calendar-month] {
    .bg-cover-inline();
    .transition(all);
    .transition-duration(300ms);
}

[data-calendar-month="0"] { background-image: url('../img/calendar/january.jpg'); }
[data-calendar-month="1"] { background-image: url('../img/calendar/february.jpg'); }
[data-calendar-month="2"] { background-image: url('../img/calendar/march.jpg'); }
[data-calendar-month="3"] { background-image: url('../img/calendar/april.jpg'); }
[data-calendar-month="4"] { background-image: url('../img/calendar/may.jpg'); }
[data-calendar-month="5"] { background-image: url('../img/calendar/june.jpg'); }
[data-calendar-month="6"] { background-image: url('../img/calendar/july.jpg'); }
[data-calendar-month="7"] { background-image: url('../img/calendar/august.jpg'); }
[data-calendar-month="8"] { background-image: url('../img/calendar/september.jpg'); }
[data-calendar-month="9"] { background-image: url('../img/calendar/october.jpg'); }
[data-calendar-month="10"] { background-image: url('../img/calendar/november.jpg'); }
[data-calendar-month="11"] { background-image: url('../img/calendar/december.jpg'); }