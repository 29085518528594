.fc {
    th {
        font-weight: 500;
        padding: 12px 12px 10px;
        text-transform: uppercase;
    }

    table {
        background: transparent;

        tr {
            & > td:first-child {
                border-left-width: 0;
            }
        }
    }

    button {
        border: 0;
        background: transparent;
        box-shadow: none;
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        display: block;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 50% !important;
        color: #333;
        .transition(all);
        .transition-duration(250ms);

        &:hover {
            background-color: #eee;
        }
    }

    div.fc-row {
        margin-right: 0;
        border: 0;
    }
}

.fc-unthemed {
    .fc-today {
        color: @m-amber;
        background-color: transparent;
    }
}

.fc-toolbar {
    margin-bottom: 0;
    padding: 15px 20px;
    position: relative;

    h2 {
        margin-top: 7px;
    }
}

.fc-event {
    padding: 0;
    font-size: 11px;
    border-radius: 2px;
    border: 0;

    .fc-title {
        padding: 2px 8px;
        display: block;
        .text-overflow();
    }

    .fc-time {
        float: left;
        background: rgba(0, 0, 0, 0.2);
        padding: 2px 6px;
        margin: 0 0 0 -1px;
    }
}

.fc-view {
    &,
    & > table {
        border: 0;
        overflow: hidden;
    }

    & > table {
        & > tbody {
            & > tr {
                .ui-widget-content {
                    border-top: 0;
                }
            }
        }
    }
}

hr.fc-divider {
    border-width: 1px;
    border-color: #eee;
}

.fc-day-grid-container.fc-scroller {
    height: auto !important;
    overflow: hidden !important;
}


/*----------------------
    Main Calendar
-----------------------*/
#calendar {
    z-index: 0;

    td, th {
        border-color: lighten(@input-border, 2%);
    }

    .fc-toolbar {
        height: 250px;
        background-color: #fff;
        border-radius: 2px 2px 0 0;
        position: relative;
        margin-bottom: -2px;
        z-index: 2;

        @media(max-width: @screen-xs-max) {
            height: 135px;
        }
    }

    .fc-day-number {
        @media screen and (min-width: @screen-sm-max) {
            font-size: 20px;
            letter-spacing: -2px;
        }

        padding-left: 10px;
        color: #ccc;
        text-align: left;
        float: left;

    }

    .fc-day-header {
        text-align: left;
    }

    .fc-day-grid-event {
        margin: 1px 9px 0;
    }
}


/*----------------------
    Calendar Widget
-----------------------*/
#calendar-widget {
    td, th {
        border-color: transparent;
    }

    .fc-toolbar {
        h2 {
            font-size: 16px;
        }
    }

    .fc-day-number {
        text-align: center;
        width: 100%;
    }

    .fc-day-grid-event {
        margin: 1px 3px 1px;

    }

    .ui-widget-header th,
    .ui-widget-header {
        border-width: 0;
    }
}