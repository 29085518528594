.swal2-modal {
    border-radius: 2px;
    padding: 30px !important;
    font-family: @font-family-base;
    box-shadow: 0 5px 20px rgba(0,0,0,.07);


    h2 {
        font-size: 16px;
        position: relative;
        z-index: 1;
        color: #333;
        line-height: inherit;
        margin: 0 0 5px;
        font-weight: 500;
    }

    .swal2-icon {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .swal2-content {
        color: @text-muted;
        font-size: @font-size-base;
        font-weight: normal;
    }

    .styled {
        padding: 6px 12px;
        font-size: 12px;
        border-radius: 2px;
        margin: 0 2px;
        text-transform: uppercase;
        font-weight: normal;
        font-family: inherit;
        .transition(all);
        .transition-duration(300ms);

        &:not(:hover) {
            .opacity(0.85);
        }
    }

    hr {
        margin: 10px 0;
    }

}

.swal2-container.in {
    background-color: rgba(0,0,0,.2);
}