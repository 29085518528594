.card {
    position: relative;
    background: #fff;
    box-shadow: @card-shadow;
    margin-bottom: @grid-gutter-width;
    border-radius: 2px;

    .card-header {
        position: relative;
        display: block;
        padding: 26px 30px;
        border-radius: 2px 2px 0 0;

        h2 {
            margin: 0;
            line-height: 100%;
            font-size: 16px;
            font-weight: 400;

            small {
                display: block;
                margin-top: 8px;
                color: #AEAEAE;
                line-height: 160%;
            }
        }

        &.ch-alt {
            &:not([class*="bgm-"]) {
                background-color: #f7f7f7;
            }
        }

        .actions {
            position: absolute;
            right: 10px;
            z-index: 2;
            top: 15px;
        }

        .btn-float {
            right: 25px;
            bottom: -23px;
            z-index: 1;
        }
        
        &[class*="bgm-"] {
            h2 {
                color: #fff;
                
                small {
                    color: rgba(255,255,255,0.7);
                }
            }
        }
    }

    .card-body {
        &.card-padding {
            padding: 26px 30px;
        }

        &.card-padding-sm {
            padding: 15px;
        }
    }
}

.card-header:not(.ch-alt):not([class*="bgm-"]) + .card-padding {
    padding-top: 0;
}

.card-light {
    .card-header {
        h2, h2 small {
            color: #fff;
        }
    }
}