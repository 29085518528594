.sidebar {
    position: fixed;
    background: #fff;
    height: ~"calc(100% - 70px)";
    top: @header-height;
    .transition(all);
    .transition-duration(300ms);
    z-index: 1000;
    overflow-y: auto;
    box-shadow: @card-shadow;
}

#sidebar {
    width: @sidebar-left-width;

    @media(max-width: (@screen-md-max + 80)) {
        .translate3d(-(@sidebar-left-width + 20), 0, 0);
        box-shadow: 0 0 20px rgba(14, 18, 21, 0.38);


        &.toggled {
            .translate3d(0, 0, 0);

        }
    }
}

.sidebar-alt {
    top: 0;
    height: 100%;

    .translate3d(-@sidebar-left-width, 0, 0);

    &.toggled {
        .translate3d(0, 0, 0);
    }
}

.s-profile {
    & > a {
        display: block;
        height: 129px;
        margin-bottom: 5px;
        width: 100%;
        background: url(../img/profile-menu.png) no-repeat left top;
        background-size: 100%;

        .sp-pic {
            padding: 12px;

            & > img {
                width: 47px;
                height: 47px;
                border-radius: 50%;
                border: 3px solid rgba(0, 0, 0, 0.14);
                box-sizing: content-box;
            }
        }

        .sp-info {
            background: rgba(0, 0, 0, 0.37);
            padding: 7px 14px;
            color: #fff;
            margin-top: 20px;
            position: relative;

            & > i {
                font-size: 19px;
                line-height: 100%;
                position: absolute;
                right: 15px;
                top: 7px;
                .transition(all);
                .transition-duration(300ms);
            }
        }
    }

    .main-menu {
        display: none;
        margin: 0 0 0;

    }

    &.toggled {
        .sp-info {
            & > i {
                .rotate(180deg);
            }
        }
    }
}

.main-menu {
    list-style: none;
    padding-left: 0;
    margin: 20px 0 0 0;

    a {
        .transition(color);
        .transition-duration(300ms);
    }

    a:hover,
    .active > a,
    a.active {
        color: #262626;
    }

    & > li {
        & > a {
            padding: 14px 20px 14px 65px;
            display: block;
            font-weight: 500;
            position: relative;
            color: #4C4C4C;

            & > i {
                position: absolute;
                left: 25px;
                font-size: 18px;
                top: 2px;
                width: 25px;
                text-align: center;
                padding: 13px 0;
            }
        }

        & > a:hover,
        &.active > a {
            background-color: #f7f7f7;
        }
    }
}

.sub-menu {
    & > a {
        position: relative;

        &:before, &:after {
            position: absolute;
            top: 50%;
            margin-top: -11px;
            font-family: @font-icon-md;
            font-size: 17px;
            right: 15px;
            .transition(all);
            .transition-duration(250ms);
        }

        &:before {
            content: "\f278";
            .scale(1);
        }

        &:after {
            content: "\f273";
            .scale(0);
        }
    }

    &.toggled {
        & > a {
            &:before {
                content: "\f278";
                .scale(0);
            }

            &:after {
                content: "\f273";
                .scale(1);
            }
        }
    }

    ul {
        list-style: none;
        display: none;
        padding: 0;

        & > li {
            & > a {
                padding: 8px 20px 8px 65px;
                font-weight: 500;
                display: block;
                color: #989898;
            }

            &:first-child > a {
                padding-top: 14px;
            }

            &:last-child > a {
                padding-bottom: 16px;
            }

            ul {
                font-size: 12px;
                margin: 10px 0;
                background-color: @app-gray;
            }
        }
    }

    &.active {
        & > ul {
            display: block;
        }
    }
}

/*-------------------------
    For Stupid IE9
--------------------------*/
.ie9 {
    #sidebar {
        @media(max-width: (@screen-md-max + 80)) {
            display: none;

            &.toggled {
                display: block;
            }
        }
    }

    .sidebar-alt {
        display: none;

        &.toggled {
            display: block;
        }
    }

}
