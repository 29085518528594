#profile-main {
    min-height: 500px;
    position: relative;

    .pm-overview {

        @media (min-width: 1200px) {
            width: 300px;
        }

        @media (min-width: @screen-sm-min) and (max-width: 1200px) {
            width: 250px;
        }

        @media (min-width: @screen-sm-min) {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            border-right: 2px solid #eee;
        }

        @media (max-width: @screen-xs-max) {
            width: 100%;
            background: #333;
            text-align: center;
        }

    }

    .pm-body {
        @media (min-width: 1200px) {
            padding-left: 300px;
        }

        @media (min-width: @screen-sm-min) and (max-width: 1200px) {
            padding-left: 250px;
        }

        @media (max-width: @screen-xs-max) {
            padding-left: 0;
        }

    }

    .pmo-pic {
        position: relative;
        margin: 20px;

        img {
            @media(min-width: @screen-sm-min) {
                width: 100%;
                border-radius: 2px 2px 0 0;
            }

            @media(max-width: @screen-xs-max) {
                width: 180px;
                display: inline-block;
                height: 180px;
                border-radius: 50%;
                border: 4px solid #fff;
                box-shadow: 0 2px 5px rgba(0,0,0,.16),0 2px 10px rgba(0,0,0,.12);
            }
        }

        .pmo-stat {
            border-radius: 0 0 2px 2px;
            color: #fff;
            text-align: center;
            padding: 30px 5px 0;

            @media(min-width: @screen-sm-min) {
                 background: @m-amber;
                 padding-bottom: 15px;
            }
        }

        .pmop-edit {
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            background: rgba(0, 0, 0, 0.38);
            text-align: center;
            padding: 10px 10px 11px; 

            &:hover {
                background: rgba(0, 0, 0, 0.8);
            }

            i {
                font-size: 18px;
                vertical-align: middle;
                margin-top: -3px;
            }

            @media (min-width: @screen-sm-min) {
                width: 100%;
                .opacity(0);

                i {
                    margin-right: 4px;
                }
            }
        }

        &:hover {
            .pmop-edit {
                .opacity(1);
            }
        }

        .pmop-message  {
            position: absolute;
            bottom: 27px;
            left: 50%;
            margin-left: -25px;

            .dropdown-menu {
                padding: 5px 0 55px;
                left: -90px;
                width: 228px;
                height: 150px;
                top: -74px;
                .transform-origin(center);

                textarea {
                    width: 100%;
                    height: 95px;
                    border: 0;
                    resize: none;
                    padding: 10px 19px;
                }

                button {
                    bottom: 5px;
                    left: 88px;
                }
            }
        }
    }

    .pmb-block {
        margin-bottom: 20px;

        @media (min-width: 1200px) {
            padding: 40px 42px 0;
        }

        @media (max-width: 1199px) {
            padding: 30px 20px 0;
        }

        &:last-child {
            margin-bottom: 50px;
        }

        .pmbb-header {
            margin-bottom: 25px;
            position: relative;

            .actions {
                position: absolute;
                top: -2px;
                right: 0;
            }

            h2 {
                margin: 0;
                font-weight: 100;
                font-size: 20px;
            }
        }

        .pmbb-edit {
            position: relative;
            z-index: 1;
            display: none;
        }

        .pmbb-edit,
        .pmbb-view {
            .animated(fadeIn, 1000ms);
        }

        &.toggled {
            .pmbb-edit {
                display: block;
            }

            .pmbb-view {
                display: none;
            }
        }
    }

    .pmo-block {
        padding: 25px;

        & > h2 {
            font-size: 16px;
            margin: 0 0 15px;
        }
    }

    .pmo-items {
        .pmob-body {
            padding: 0 10px;
        }

        a {
            display: block;
            padding: 4px;

            img {
                width: 100%;
            }
        }
    }
}

.pmo-contact {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            padding: 8px 0 8px 35px;

            i {
                font-size: 18px;
                vertical-align: top;
                line-height: 100%;
                position: absolute;
                left: 0;
                width: 18px;
                text-align: center;
            }
        }
    }
}

.pmo-map {
    margin: 20px -21px -18px;
    display: block;

    img {
        width: 100%;
    }
}

.c-timeline {
    @media(max-width: @screen-xs-max) {
        background: @body-bg;
        box-shadow: none;

        .tab-nav {
            background: #fff;
            box-shadow: 0 1px 1px rgba(0,0,0,.15);
        }
    }
}

.timeline {
    position: relative;

    @media(min-width: @screen-sm-min) {
        padding: 50px;
        padding-left: 100px;
    }

    @media(max-width: @screen-xs-max) {
        margin-top: 30px;
    }
}

.t-view {
    border: 1px solid #eee;
    position: relative;
    margin-bottom: 35px;

    @media(max-width: @screen-xs-max) {
        background: #fff;
        box-shadow: 0 1px 1px rgba(0,0,0,.15);
    }

    .tv-header {
        padding: 16px 18px;
        border-bottom: 1px solid #eee;
        background: #F9F9F9;

        .actions {
            position: absolute;
            top: 5px;
            right: 10px;
        }
    }

    .tv-body {
        padding: 23px 25px;

        .tvb-lightbox {
            margin: 0 -8px 15px;

            [data-src] {
                padding: 0 5px;
                margin-bottom: 5px;
            }
        }
    }

    .tvh-user {
        display: block;

        img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
        }
    }

    &:before {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        left: -70px;
        top: 0;
        border: 3px solid #FFF;
        text-align: center;
        font-size: 16px;
        line-height: 34px;
        color: #FFF;
        font-family: @font-icon-md;
        z-index: 1;

    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -50px;
        width: 1px;
        height: ~"calc(100% + 37px)";

    }

    &[data-tv-type="text"] {
        &:before {
            content: "\f24f";
            background: @m-cyan;
            box-shadow: 0 0 0 1px @m-cyan;
        }

        &:after {
            background: @m-cyan;
        }
    }

    &[data-tv-type="image"] {
        &:before {
            content: "\f17f";
            background: @m-green;
            box-shadow: 0 0 0 1px @m-green;
        }

        &:after {
            background: @m-green;
        }
    }

    &[data-tv-type="video"] {
        &:before {
            content: "\f3a9";
            background: @m-amber;
            box-shadow: 0 0 0 1px @m-amber;
        }

        &:after {
            background: @m-amber;
        }
    }


    .tvb-stats {
        list-style: none;
        padding: 0;
        margin: 10px 0 20px;

        & > li {
            display: inline-block;
            padding: 5px 10px 6px;
            border: 1px solid #ccc;
            margin-right: 2px;

            i {
                font-size: 15px;
                line-height: 100%;
                vertical-align: top;
                margin-top: 2px;
            }

            &.tvbs-comments {
                border-color: @m-green;
                color: @m-green;
            }

            &.tvbs-likes {
                border-color: @m-lightblue;
                color: @m-lightblue;
            }

            &.tvbs-views {
                border-color: @m-orange;
                color: @m-orange;
            }
        }


     }
}

.tv-comments {

    .tvc-lists {
        padding: 0;
        list-style: none;
        margin: 0;

        & > li {
            padding: 15px 20px;
            margin: 0;
            border-top: 1px solid #eee;
        }
    }
}

.tvc-more {
    color: #333;
    display: block;
    margin-bottom: -10px;

    &:hover {
        color: #000;
    }

    i {
        vertical-align: middle;
        margin-right: 5px;
    }
}

.p-header {
    position: relative;
    margin: 0 -7px;

    .actions {
        position: absolute;
        top: -18px;
        right: 0;
    }
}

.p-menu {
    list-style: none;
    padding: 0 5px;
    margin: 0 0 30px;

    & > li {
        display: inline-block;
        vertical-align: top;

        & > a {
            display: block;
            padding: 5px 20px 5px 0;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 15px;

            & > i {
                margin-right: 4px;
                font-size: 20px;
                vertical-align: middle;
                margin-top: -5px;
            }
        }

        &:not(.active) > a {
            color: #4285F4;

            &:hover {
                color: #333;
            }
        }

        &.active > a {
            color: #000;
        }
    }

    .pm-search {
        @media(max-width: @screen-sm-max) {
            margin: 20px 2px 30px;
            display: block;

            input[type="text"] {
                width: 100%;
                border: 1px solid #ccc;
            }
        }
    }

    .pms-inner {
        margin: -2px 0 0;
        position: relative;
        top: -2px;
        overflow: hidden;
        white-space: nowrap;

        i {
            vertical-align: top;
            font-size: 20px;
            line-height: 100%;
            position: absolute;
            left: 9px;
            top: 8px;
            color: #333;
        }

        input[type="text"] {
            height: 35px;
            border-radius: 2px;
            padding: 0 10px 0 40px;

            @media(min-width:  @screen-sm-min) {
                border: 1px solid #fff;
                width: 50px;
                background: transparent;
                position: relative;
                z-index: 1;
                .transition(all);
                .transition-duration(300ms);

                &:focus {
                    border-color: #DFDFDF;
                    width: 200px;
                }
            }
        }
    }
}
