.dw-item {
    position: relative;
    min-height: 400px;
    margin-bottom: 30px;
    box-shadow: @card-shadow;
}

.dwi-header {
    position: relative;
}

.dwi-header-img {
    .bg-cover-inline();
    height: 155px;
}

.dw-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.dwih-title {
    padding: 12px 20px;
    position: absolute;
    width: 100%;
    left: 0;
}

/*-------------------------
   Site Visits
--------------------------*/
#site-visits {
    .dwi-header {
        padding-bottom: 38px;
        margin-bottom: 12px;
        background-color: rgba(255, 255, 255, 0.05);

        canvas {
            width: 100% !important;
        }
    }

    .dwih-title {
        bottom: 0;
        color: #fff;
    }

    .sv-item {
        &:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.05);
        }

        small {
            color: rgba(255, 255, 255, 0.7);
        }

        h3 {
            font-weight: normal;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
        }

        .pull-right {
            margin-top: 5px;
            .opacity(0.7);
        }
    }
}


/*-------------------------
    Best Selling Item
--------------------------*/
#best-selling {
    background-color: #fff;
    
    .dwi-header {
        .dwih-title {
            padding-bottom: 30px;
            top: 0;
            color: #fff;
            #gradient > .vertical(rgba(0,0,0,0.6); rgba(0,0,0,0));
        }
        
        .bs-main {
            padding: 15px;
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            #gradient > .vertical(rgba(0,0,0,0); rgba(0,0,0,0.6));
            
            & > h2 {
                font-weight: 400;
                font-size: 20px;
                margin: 5px 0 0 0;
                line-height: 100%;
                color: #fff;
            }
        }
    }
}


/*-------------------------
    Weather
--------------------------*/
#weather-widget {
    color: #fff;
    padding: 20px 20px 0;
    
    .weather-status {
        font-size: 40px;
        line-height: 100%;
    }
    
    .weather-icon {
        text-align: center;
        margin-top: 10px;
        height: 150px;
        .bg-option();
        
        /* Weather Icons */
        .wi-item(@icon) {
            .img-retina('../img/icons/weather/@{icon}.png', '../img/icons/weather/@{icon}@2x.png', 125px, 125px);
        }
        
        &.wi-0 { .wi-item(0); }
        &.wi-1 { .wi-item(1); }
        &.wi-2 { .wi-item(2); }
        &.wi-3 { .wi-item(3); }
        &.wi-4 { .wi-item(2); }
        &.wi-5 { .wi-item(5); }
        &.wi-6 { .wi-item(5); }
        &.wi-7 { .wi-item(5); }
        &.wi-8 { .wi-item(5); }
        &.wi-9 { .wi-item(9); }
        &.wi-10 { .wi-item(5); }
        &.wi-11 { .wi-item(9); }
        &.wi-12 { .wi-item(9); }
        &.wi-13 { .wi-item(9); }
        &.wi-14 { .wi-item(9); }
        &.wi-15 { .wi-item(5); }
        &.wi-16 { .wi-item(9); }
        &.wi-17 { .wi-item(5); }
        &.wi-18 { .wi-item(18); }
        &.wi-19 { .wi-item(19); }
        &.wi-20 { .wi-item(19); }
        &.wi-21 { .wi-item(19); }
        &.wi-22 { .wi-item(19); }
        &.wi-23 { .wi-item(19); }
        &.wi-24 { .wi-item(24); }
        &.wi-25 { .wi-item(24); }
        &.wi-26 { .wi-item(26); }
        &.wi-27 { .wi-item(27); }
        &.wi-28 { .wi-item(28); }
        &.wi-29 { .wi-item(27); }
        &.wi-30 { .wi-item(28); }
        &.wi-31 { .wi-item(31); }
        &.wi-32 { .wi-item(32); }
        &.wi-33 { .wi-item(31); }
        &.wi-34 { .wi-item(32); }
        &.wi-35 { .wi-item(5); }
        &.wi-36 { .wi-item(32); }
        &.wi-37 { .wi-item(2); }
        &.wi-38 { .wi-item(2); }
        &.wi-39 { .wi-item(2); }
        &.wi-40 { .wi-item(5); }
        &.wi-41 { .wi-item(5); }
        &.wi-42 { .wi-item(9); }
        &.wi-43 { .wi-item(5); }
        &.wi-44 { .wi-item(27); }
        &.wi-45 { .wi-item(2); }
        &.wi-46 { .wi-item(18); }
        &.wi-47 { .wi-item(2); }
    }
    
    .weather-info {
        list-style: none;
        padding: 0;
        margin: 3px 0 0 0;
        
        & > li {
            display: inline-block;
            border: 1px solid rgba(255, 255, 255, 0.39);
            padding: 2px 10px 3px;
            margin-right: 5px;
        }
    }

    .dw-footer {
        background: rgba(0, 0, 0, 0.04);
        padding: 10px 20px;
    }
    
    .weather-list {
        font-size: 16px;
        .text-overflow();
            
        & > span {
            margin-right: 7px;
            display: inline-block;
            line-height: 40px;
            vertical-align: top;
            
            .wli-icon(@icon) {
                background-image: url('../img/icons/weather/@{icon}.png');
            }
            
            &.weather-list-icon {
                width: 35px;
                height: 35px;
                .bg-option();
                background-size: 30px 30px;
                
                &.wi-0 { .wli-icon(0); }
                &.wi-1 { .wli-icon(1); }
                &.wi-2 { .wli-icon(2); }
                &.wi-3 { .wli-icon(3); }
                &.wi-4 { .wli-icon(2); }
                &.wi-5 { .wli-icon(5); }
                &.wi-6 { .wli-icon(5); }
                &.wi-7 { .wli-icon(5); }
                &.wi-8 { .wli-icon(5); }
                &.wi-9 { .wli-icon(9); }
                &.wi-10 { .wli-icon(5); }
                &.wi-11 { .wli-icon(9); }
                &.wi-12 { .wli-icon(9); }
                &.wi-13 { .wli-icon(9); }
                &.wi-14 { .wli-icon(9); }
                &.wi-15 { .wli-icon(5); }
                &.wi-16 { .wli-icon(9); }
                &.wi-17 { .wli-icon(5); }
                &.wi-18 { .wli-icon(18); }
                &.wi-19 { .wli-icon(19); }
                &.wi-20 { .wli-icon(19); }
                &.wi-21 { .wli-icon(19); }
                &.wi-22 { .wli-icon(19); }
                &.wi-23 { .wli-icon(19); }
                &.wi-24 { .wli-icon(24); }
                &.wi-25 { .wli-icon(24); }
                &.wi-26 { .wli-icon(26); }
                &.wi-27 { .wli-icon(27); }
                &.wi-28 { .wli-icon(28); }
                &.wi-29 { .wli-icon(27); }
                &.wi-30 { .wli-icon(28); }
                &.wi-31 { .wli-icon(31); }
                &.wi-32 { .wli-icon(32); }
                &.wi-33 { .wli-icon(31); }
                &.wi-34 { .wli-icon(32); }
                &.wi-35 { .wli-icon(5); }
                &.wi-36 { .wli-icon(32); }
                &.wi-37 { .wli-icon(2); }
                &.wi-38 { .wli-icon(2); }
                &.wi-39 { .wli-icon(2); }
                &.wi-40 { .wli-icon(5); }
                &.wi-41 { .wli-icon(5); }
                &.wi-42 { .wli-icon(9); }
                &.wi-43 { .wli-icon(5); }
                &.wi-44 { .wli-icon(27); }
                &.wi-45 { .wli-icon(2); }
                &.wi-46 { .wli-icon(18); }
                &.wi-47 { .wli-icon(2); }
            }
            
            & > i {
                line-height: 100%;
                font-size: 39px;
            }
        }
    }
}


/*-------------------------
    Profile View
--------------------------*/
.profile-view {
    text-align: center;
    .pv-header {
        position: relative;
        height: 145px;
        width: 100%;
        .bg-cover('../img/headers/sm/4.png');
        
        & > .pv-main {
            border-radius: 50%;
            width: 130px;
            position: absolute;
            height: 130px;
            bottom: -50px;
            left: 50%;
            margin-left: -65px;
          .transition(all);
          .transition-duration(300ms);
        }
    }
    
    .pv-body {
        margin-top: 70px;
        padding: 0 20px 20px;
        
        & > h2 {
            margin: 0;
            line-height: 100%;
            font-size: 20px;
            font-weight: 400;
        }
        
        & > small {
            display: block;
            color: #8E8E8E;
            margin: 10px 0 15px;
        }
        
        .pv-contact,
        .pv-follow {
            padding: 0;
            list-style: none;
            
            & > li {
                display: inline-block;
            }
        }
        
        .pv-contact {
            & > li {
                margin: 0 5px;
                
                & > .zmdi {
                    line-height: 100%;
                    vertical-align: text-bottom;
                    font-size: 22px;
                }
            }
        }
        
        .pv-follow-btn {
            padding: 7px 20px;
            background: @m-cyan;
            color: #fff;
            border-radius: 3px;
            text-transform: uppercase;
            max-width: 200px;
            width: 100%;
            display: inline-block;
            
            &:hover {
                background: darken(@m-cyan, 5%);
            }
        }
    }

  &:hover {
    .pv-main {
      .scale(1.2);
    }
  }
}


/*-------------------------
    Picture List
--------------------------*/
.picture-list {
    .pl-body {
        padding: 2px;
        
        [class*="col-"] {
            padding: 2px;
            
            & > a {
                display: block;

                img { 
                    width: 100%;
                }
            }
            
        }
        
        .clearfix();
    }
}


/*-------------------------
    Social
--------------------------*/
.go-social {
    .card-body {
        padding: 0 15px 20px;
        
        [class*="col-"] {
            padding: 12px;
            
            img {
                .transition(all);
                .transition-duration(200ms);
                .backface-visibility(hidden);    
            }
            
            &:hover {
                img {
                    .scale(1.2);
                }
            }
        }
    }
}


/*-------------------------
    Rating
--------------------------*/
.rating-list {
    padding: 0 0 20px;

    .rl-star {
        margin-top: 10px;
        margin-bottom: 4px;

        .zmdi {
            font-size: 20px;
            color: #ccc;

            &.active {
                color: @m-orange;
            }
        }
    }

    .media {
        .zmdi-star {
            line-height: 100%;
            font-size: 22px;
            vertical-align: middle;
            position: relative;
            top: -2px;
            left: 6px;
            color: @m-orange;
        }

        .media-body {
            padding: 7px 10px 0 5px;
        }
    }
}


/*-------------------------
    Calendar
--------------------------*/
.cwh-year {
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
}

.cwh-day {
    font-size: 25px;
    line-height: 100%;
    color: #fff;
}


/*-------------------------
    Event List
--------------------------*/
.event-date {
    width: 70px;
    text-align: center;
    border-radius: 2px;
    padding: 6px 5px;
    margin-top: -3px;
    position: relative;
    color: #fff;
}

.ed-day {
    display: block;
    font-size: 20px;
    line-height: 100%;
}

.ed-month-time {
    font-size: 12px;
    display: block;
    margin-top: 1px;
    line-height: 100%;
}