.alert {
    padding-left: 30px;
    font-size: 13px;
    
    span {
        cursor: pointer;
    }

    &:not(.alert-dismissible) {
        padding-right: 30px;
    }

    &.alert-dismissable {
        padding-right: 44px;
    } 
}

.alert-inverse {
    .alert-variant(@alert-inverse-bg; @alert-inverse-border; @alert-inverse-text);
}

.growl-animated {
    &.alert-inverse {
        box-shadow: 0 0 5px fade(@alert-inverse-bg, 50%);
    }
    
    &.alert-info {
        box-shadow: 0 0 5px fade(@alert-info-bg, 50%);
    }

    &.alert-success {
        box-shadow: 0 0 5px fade(@alert-success-bg, 50%);
    }

    &.alert-warning {
        box-shadow: 0 0 5px fade(@alert-warning-bg, 50%);
    }

    &.alert-danger {
        box-shadow: 0 0 5px fade(@alert-danger-bg, 50%);
    }
}

.alert-link {
    color: #fff !important;
}