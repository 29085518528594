.visitors-stats {
    margin: 0 -6px 30px;

    .col-xs-6 {
        padding: 0 6px;
    }
}

.visitors-stats-item {
    border: 1px solid #eee;
    padding: 13px 17px 12px;
    border-radius: 2px;
    
    strong {
        font-size: 25px;
        font-weight: normal;
        color: #333;
        line-height: 100%;
    }
    
    small {
        display: block;
        margin-top: 3px;
        color: @text-muted;
    }
}

.analytics-img-country {
    height: 13px;
    width: 18px;
    vertical-align: top;
    position: relative;
    top: 2px;
    margin-right: 5px;
    left: -1px;
    border-radius: 1px;
}

.analytics-img-browser {
    width: 20px;
    vertical-align: top;
    margin: -2px 10px 0 0;
    top: 1px;
    position: relative;
}