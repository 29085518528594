.noUi-target {
    border-radius: 0;
    box-shadow: none;
    border: 0;
}

.noUi-background {
    background: #d4d4d4;
    box-shadow: none;
}

.noUi-horizontal {
    height: 3px;

    .noUi-handle {
        top: -8px;
    }
}

.noUi-vertical {
    width: 3px;
}

.noUi-connect {
  background: @m-teal;
}

.noUi-horizontal,
.noUi-vertical {
    .noUi-handle {
        width: 19px;
        height: 19px;
        border: 0;
        border-radius: 100%;
        box-shadow: none;
        .transition(box-shadow);
        .transition-duration(200ms);
        cursor: pointer;
        position: relative;
        background-color: @m-teal;

        &:before,
        &:after {
            display: none;
        }
    }

    .noUi-active {
        box-shadow: 0 0 0 13px rgba(0,0,0,0.1);
    }
}

.noUi-tooltip {
  border: 0;
  background: #d4d4d4;
  padding: 5px 10px;
}