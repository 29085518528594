.cp-container {
    position: relative;
    
    & > .input-group {
        
        input.cp-value {
            color: #000 !important;
            background: transparent !important;
        }
        
        .dropdown-menu {
            padding: 20px;
        }
    }
    
    i.cp-value {
        width: 25px;
        height: 25px;
        border-radius: 2px;
        position: absolute;
        top: 0;
        right: 15px;
    }
} 